<template>
  <v-row no-gutters justify="center" align="center" class="mt-4">
    <v-card width="1000px" height="800">
      <v-container>
        <h2 class="my-5 headtitle">จัดการแบบสอบถาม</h2>
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mt-4"
            color="#833133"
            style="color: white"
            @click="createForm()"
            ><v-icon left dark> mdi-plus-circle </v-icon>เพิ่ม
          </v-btn>
        </v-row>
        <v-data-table
          :headers="headers"
          color="red"
          :items="list"
          :search="search"
          :items-per-page="10"
          class="elevation-1"
        >
          <!-- <template v-slot:[`item.count`]="{ item }">
            <span style="color: #833133">{{ item.count }}</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span style="color: #833133">{{ item.name }}</span>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <span style="color: #833133">{{ item.description }}</span>
          </template> -->
          <!-- color: #833133; -->

          <!-- <template v-slot:[`item.activeFlag`]="{ item }">
          <v-row justify="center">
            <v-btn v-if="item.activeFlag === true" disabled>อนุมัติแล้ว</v-btn>
            <v-btn @click="approve(item)" v-else>รออนุมัติ</v-btn>
          </v-row>
        </template> -->
          <template v-slot:[`item.action`]="{ item }">
            <v-row justify="center">
              <v-col cols="4"
                ><v-icon @click="DeleteForm(item)">mdi-delete</v-icon>
              </v-col>
              <v-col cols="4">
                <v-icon @click="UpdateForm(item)">mdi-pencil</v-icon></v-col
              >
              <v-col cols="4"
                ><v-icon @click="GetQuestions(item)">mdi-magnify</v-icon></v-col
              >
            </v-row>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-row>
</template>

<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      search: "",
      //   dialog: false,
      headers: [
        {
          text: "ลำดับ",
          value: "count",
          align: "center",
          
        },
        {
          text: "ชื่อแบบสอบถาม",
          value: "name",
          align: "center",
          
        },
        {
          text: "รายละเอียด",
          value: "description",
          align: "center",
          
        },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      list: [],
      count: 0,
    };
  },
  created() {
    this.getForm();
  },
  methods: {
    async getForm() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllForm`
      );
      console.log("response", response.data.data);
      this.list = response.data.data;
      for (let i in this.list) {
        this.list[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },

    async GetQuestions(val) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getForm/` + val.id
      );
      console.log("response111", response.data.data);
      localStorage.setItem("Questions", Encode.encode(response.data.data));
      this.$router.push("ManageQuestions");
    },
    async UpdateForm(val) {
      localStorage.setItem("Questions", Encode.encode(val));
      this.$router.push("UpdateForm");
      console.log(response);
    },
    async DeleteForm(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/form/removeForm/` + val.id
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          await location.reload();
        }
      });
    },
    createForm() {
      this.$router.push("CreateForm");
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
/* .v-data-table__mobile-row__header {
  color: #833133;
} */
.v-data-table__mobile-row__header {
  font-weight: 600;
  color: #833133;
}
</style>