<template>
  <div>
    <ManageForm />
  </div>
</template>
<script>
import ManageForm from "@/components/ManageForm";
export default {
  components: {
    ManageForm,
  },
  created() {
    // if (localStorage.getItem('user') === null) {
    //   this.$router.push('/Login').catch(() => {})
    // }
  },
};
</script>
